<template>

  <div class="cats"> 

    <div :class="'cat positive cnt_' + positiveItems.length" v-if="positiveItems.length">
      <div class="title">Positive Effects:</div>
      <div class="list">
        <div class="imgs">
          <img v-for="(item, kitem) in positiveItems" :src="testImgsPositive[kitem]"/> 
        </div>
        <div class="names">
          <div class="item" v-for="(item) in positiveItems">{{ item }}</div>
        </div>
      </div>
    </div>

    <div :class="'cat negative cnt_' + negativeItems.length" v-if="negativeItems.length">
      <div class="title">Negative Effects:</div>
      <div class="list">
        <div class="imgs">
          <img v-for="(item, kitem) in negativeItems" :src="testImgsNegative[kitem]"/> 
        </div>
        <div class="names">
          <div class="item" v-for="(item) in negativeItems">{{ item }}</div>
        </div>
      </div>
    </div>

    <div :class="'cat medical cnt_' + medicalItems.length" v-if="medicalItems.length">
      <div class="title">Conditions:</div>
      <div class="list">
        <div class="imgs">
          <img v-for="(item, kitem) in medicalItems" :src="testImgsMedical[kitem]"/> 
        </div>
        <div class="names">
          <div class="item" v-for="(item) in medicalItems">{{ item }}</div>
        </div>
      </div>
    </div> 

    <div :class="'cat tastes cnt_' + tasteItems.length" v-if="tasteItems.length">
      <div class="title">Tastes</div>
      <div class="list">
        <div class="imgs">
          <img v-for="(item, kitem) in tasteItems" :src="testImgsTaste[kitem]"/> 
        </div>
        <div class="names">
          <div class="item" v-for="(item) in tasteItems">{{ item }}</div>
        </div>
      </div>
    </div>

  </div>
 
</template>

<script setup>

const props = defineProps({
  positive: {
    type: String,
    default: ''
  },
  negative: {
    type: String,
    default: ''
  },
  medical: {
    type: String,
    default: ''
  },
  tastes: {
    type: String,
    default: ''
  },
})

const positiveItems = ref(props.positive ? props.positive.split(',') : [])
const negativeItems = ref(props.negative ? props.negative.split(',') : [])
const medicalItems = ref(props.medical ? props.medical.split(',') : [])
const tasteItems = ref(props.tastes ? props.tastes.split(',') : [])
 

const testImgsPositive = ref([
  '/images/effect/paranoia.jpg',
  '/images/effect/dry_mouth.jpg',
  '/images/effect/insomnia.jpg',
])

const testImgsNegative = ref([
  '/images/effect/paranoia.jpg',
  '/images/effect/dry_mouth.jpg',
  '/images/effect/insomnia.jpg',
])

const testImgsMedical = ref([
  '/images/effect/help_stress.jpg',
  '/images/effect/help_pain.jpg',
  '/images/effect/help_depression.jpg',
])

const testImgsTaste = ref([
  '/images/taste/sweet.jpg',
  '/images/taste/citrus.jpg',
  '/images/taste/fruity.jpg',
])

</script>




<style scoped>


.cats {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  color: var(--un-text-color);
  margin-top: 1rem;
  gap: 2rem
}

.cat {
  flex-shrink: 0;
  flex: 0 0 auto; 
  min-width: 0;
  flex-shrink: 0;
  flex: 0 0 auto; 
  min-width: 0;
}

.cats-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto; 
  white-space: nowrap;
  color: var(--un-text-color);
  padding: 0 10px; 

  box-sizing: border-box;
  max-width: 100%;
}

.cat .title {
  font-weight: bold;
  margin-bottom: 10px;
}

.cat .list {
  display: flex;
  gap: 10px;
}

.cat .list .imgs{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 70px;
  gap: 2px 5px;
  flex-shrink: 0;
}
.cat .list .imgs img{
  width: 28px;
  height: 28px;
}
.cat.negative .list .imgs img{
  filter: hue-rotate(330deg);
}

/* 1 item */

.cat.cnt_1 .list .imgs img{
  width: 100%;
  object-fit: contain;
  height: 65px;
  padding: 10px;
}

/* 2 items */

.cat.cnt_2 .list .imgs img:nth-child(1){
  width: 100%;
  object-fit: contain;
  padding-right: 20px;
}
.cat.cnt_2 .list .imgs img:nth-child(2){
  width: 100%;
  object-fit: contain;
  padding-left: 20px;
}

/* 3 items */
.cat.cnt_3 .list .imgs img:nth-child(1){
  width: 100%;
  object-fit: contain;
}
.cat.cnt_3 .list .imgs img:nth-child(2){

}
.cat.cnt_3 .list .imgs img:nth-child(3){

}
.cat .list .names{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cat .item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cat .item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}



@container pb (max-width: 650px) {
  .cats {
    overflow-x: auto;
    width: 100%;
    max-width: calc(100vw - 40px);
  }
  .cats::-webkit-scrollbar {
    display: none;
  }
  
}


  
</style>
