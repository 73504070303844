<template>

  <div class="pbf">

    <template v-if="strainData?.id">

    <BrandHeadStrain
      :id="strainData.id"
      :nm="strainData.name"
      :nmalt="strainData.name"
      :info="strainData.description"
      :avatar="strainData.cover_s"
      :link="strainData.section"
      :link-shop="strainData.link_shop"
      :pos="strainData.item_stat?.rate"
      :rate="strainData.item_stat?.avg_rate"
      :cnt-reports="strainData.item_stat?.cnt_reports"
      :cnt-harvests="strainData.item_stat?.cnt_harvests"
      :weight-plant="strainData.item_stat?.avg_weight_plant"
      :effects="strainData.items_harvest_effect"
      :positive="strainData.props.seed_strain_effects_positive"
      :negative="strainData.props.seed_strain_effects_negative"
      :medical="strainData.props.seed_strain_medical"
      :tastes="strainData.props.seed_strain_tastes"
      :cnt_followers="strainData.item_stat?.cnt_followers"
      :isfollow="strainData?.addon?.follow"
      :type="type"
      :offical_user="strainData.offical_user"
      :type-flowering="strainData.props.seed_flowering_type"
      :height="strainData.props.seed_strain_height"
      :height2="51"
      :flowering="strainData.props.seed_strain_flowering_days"
      :genes="strainData.props.seed_strain_genes"
      :yield="strainData.props.seed_strain_yield"
      :difficulty="strainData.props.seed_strain_difficulty"
      :cbd="strainData.props.seed_strain_cbd"
      :thc="strainData.props.seed_strain_thc"
      :bread="[
        {name: 'Strains', link: '/strains2'},
        {name: strainData.name, link: strainData.section},
      ]"
      @follow="follow(1)"
      @unfollow="follow(0)"
      >

      </BrandHeadStrain>

 

    <div class="pb grid_width">  

    <UiTabs 
      :tabs="getTabs" 
      :acttab="active_tab"
      />    
      <br>

      <NuxtPage 
        :data="strainData"
        :id="strainData.id"
        />
    </div>

    
    <BrandContextMenu 
      :is-follow="strainData?.addon?.follow" 
      @follow="follow(1)"
      @unfollow="follow(0)"
      />

    </template> 
  
  <template v-else>
    <SkBrandOpened />
  </template> 
      
  </div>
</template>

<script setup>



const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $ga, $follow, $popup} = useNuxtApp()
var link = ref(route.params['id']);
var active_tab = ref('info');
var type = ref('seed');



const getTabs = computed(() => {  

  var items = [];

  items.push({
    id: 'info',
    name: 'Info',
    link: strainData.value?.link + '',
    count: 0,
    icon: '',
  });

  if(strainData.value?.item_stat?.cnt_reports){
    items.push({
      id: 'diaries',
      name: 'Diaries',
      link: strainData.value?.link + '/diaries',
      count: strainData.value?.item_stat?.cnt_reports,
      icon: '',
    });
  }


  // if(strainData.value?.item_stat?.cnt_photos){
  //   items.push({
  //     id: 'gallery',
  //     name: 'Gallery',
  //     link: strainData.value?.link + '/gallery',
  //     count: strainData.value?.item_stat?.cnt_photos,
  //     icon: '',
  //   });
  // }

  // if(strainData.value?.item_stat?.cnt_growers){
  //   items.push({
  //     id: 'growers',
  //     name: 'Growers',
  //     link: strainData.value?.link + '/growers',
  //     count: strainData.value?.item_stat?.cnt_growers,
  //     icon: '',
  //   });
  // }

  if(strainData.value?.item_stat?.cnt_products){
    items.push({
      id: 'Products',
      name: 'Products',
      link: strainData.value?.link + '/products',
      count: strainData.value?.item_stat?.cnt_products,
      icon: '',
    });
  }

  if(strainData.value?.item_stat?.cnt_harvests){
    items.push({
      id: 'Harvests',
      name: 'Harvests',
      link: strainData.value?.link + '/harvests',
      count: strainData.value?.item_stat?.cnt_harvests,
      icon: '',
    });
  }

  if(strainData.value?.item_stat?.cnt_reviews){
    items.push({
      id: 'reviews',
      name: 'Reviews',
      link: strainData.value?.link + '/reviews',
      count: strainData.value?.item_stat?.cnt_reviews,
      icon: '',
    });
  }

  return items;
 
})
 
const follow = function(state) {  
  $follow.toggle('product', strainData.value.id, state, {
    type: 'product',
    id: strainData.value.id,
    avatar: strainData.value.avatar_small, 
    name: strainData.value.name, 
    link: strainData.value.link, 
  })
  .then((response) => {    
    strainData.value.addon.follow = state;
    if(strainData.value.item_stat)
      strainData.value.item_stat.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error')
  });
}
 


const loadData = async function(strain) {     
  const response = await $api.getUrl('/ref/strains/' + strain, {
  })  
  return response;  
}


const { pending: is_loading,  data: strainData } = await useLazyAsyncData('strain_data_' + link.value, async () => await loadData(link.value))


watch( () => strainData, () => {
  useHead(useNuxtApp().$head.getStrain(strainData.value));
}, {immediate: true, deep: true});

watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getStrain(strainData.value));
}, {immediate: true, deep: true});



onBeforeUnmount(() => {
  strainData.value = null;
})

  
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
